import { App } from "vue"
import * as components from "./components"
import { NiceUiConfig, provideNiceUiConfig } from "./useNiceUIConfig"

export * from "./useNiceUIConfig"
export * from "./components"
import * as utils from "./utils"
export { utils }

export default {
  install(app: App, options: NiceUiConfig) {
    provideNiceUiConfig(options)

    // map to kebab-case for now until we refactor to CamelCase

    app.component("nice-alert", components.NiceAlert)
    app.component("nice-badge", components.NiceBadge)
    app.component("nice-bar", components.NiceBar)
    app.component("nice-button", components.NiceButton)
    app.component("nice-cascader", components.NiceCascader)
    app.component("nice-checkbox", components.NiceCheckbox)
    app.component("nice-date-picker", components.NiceDatePicker)
    app.component("nice-dialog", components.NiceDialog)
    app.component("nice-divider", components.NiceDivider)
    app.component("nice-dropdown", components.NiceDropdown)
    app.component("nice-input", components.NiceInput)
    app.component("nice-label", components.NiceLabel)
    app.component("nice-notification", components.NiceNotification)
    app.component("nice-page-header", components.NicePageHeader)
    app.component("nice-pagination", components.NicePagination)
    app.component("nice-popover", components.NicePopover)
    app.component("nice-progress", components.NiceProgress)
    app.component("nice-radio-group", components.NiceRadioGroup)
    app.component("nice-radio", components.NiceRadio)
    app.component("nice-select-virtualized", components.NiceSelectVirtualized)
    app.component("nice-select", components.NiceSelect)
    app.component("nice-slider", components.NiceSlider)
    app.component("nice-star-rating", components.NiceStarRating)
    app.component("nice-switch", components.NiceSwitch)
    app.component("nice-tag", components.NiceTag)
    app.component("nice-textarea", components.NiceTextarea)
    app.component("nice-tooltip", components.NiceTooltip)
    app.component("nice-upload", components.NiceUpload)

    // // this will install all components by CamelCase
    // Object.keys(components).forEach(key => {
    //   app.component(key, components[key])
    // })
  },
}
